import 'firebase/firestore';
import 'firebase/functions';

import firebase from 'firebase/app';
import { functions } from '../config/store';

// Get Firestore instance
const db = firebase.firestore();

export const updateUserRoleForClient = async () => {
  try {
    const result = await functions.httpsCallable('updateClientUserRole')({
      clientId: '',
      email: 'name@email.com',
      role: '2000',
    });
    console.log('result', result);
  } catch (error) {
    console.error(error);
  }
};
