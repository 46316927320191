import {
  PATHS,
  RIGHT_ALIGN_BODY,
  RIGHT_ALIGN_HEADER,
} from '../../../../config/constants';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import CreatedTableCellCopy from '../../../../components/CreatedTableCell';
import { EMPTY_ARRAY } from '../../../../shared/helpers';
import { Paper } from '@mui/material';
import Table from '../../../../components/Table';
import TableActionButton from '../../../../components/TableActionButton';
import { UrlParams } from '../../../../types/urlParams';
import { getStorageForPath } from '../../../../shared/utilities';
import { onAssetListDelete } from '../../../../state/asset-list';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useMemo } from 'react';
import { useModal } from '../../../../components/Modal';
import { useParams } from 'react-router-dom';

const columns = [
  { accessorKey: 'name', header: 'Name' },
  { accessorKey: 'fileName', header: 'File Name' },
  {
    accessorKey: 'created',
    header: 'Created',
    Cell: CreatedTableCellCopy,
  },
  {
    accessorKey: 'numRows',
    header: 'Assets',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
];

const Edit = () => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { clientId } = useParams<UrlParams>();
  useFirestoreConnect([
    {
      collection: 'clients',
      doc: clientId,
      subcollections: [{ collection: 'assetLists' }],
      where: ['deleted', '==', 0],
      storeAs: 'assetLists',
      orderBy: ['created', 'desc'],
    },
  ]);
  const assetLists = useSelector(
    (state: RootStateOrAny) =>
      state.firestore.ordered.assetLists || EMPTY_ARRAY,
  );

  const deleteList = async (row) => {
    const { id } = row;
    const list = assetLists.find((l) => l.id === id);
    const messages = [`Are you sure you want to delete ${list.name}?`];

    const deleteConfirmed = await showModal({
      title: 'Please Confirm',
      buttons: {
        confirm: [{ text: 'Yes', value: true }],
        cancel: [{ text: 'No' }],
      },
      messages,
    });
    if (deleteConfirmed) {
      dispatch(onAssetListDelete({ id }));
    }
  };

  const onClickDownloadTaxonomy = async (rowData) => {
    const assetListStorage = getStorageForPath(PATHS.STORAGE.TAXONOMY);

    let xlsxUrl = '';

    // there was a bug that meant that files were being saved with the user defined name
    // instead of the actual file name, so we need to check for both
    try {
      const xlsxPath = `${clientId}/${rowData.name}.xlsx`;
      const xlsxRef = assetListStorage.ref(xlsxPath);
      xlsxUrl = await xlsxRef.getDownloadURL();
    } catch (e) {
      console.error(e);
      const xlsxPath = `${clientId}/${rowData.fileName}.xlsx`;
      const xlsxRef = assetListStorage.ref(xlsxPath);
      xlsxUrl = await xlsxRef.getDownloadURL();
    }

    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', xlsxUrl);
    downloadAnchorNode.setAttribute('download', xlsxUrl);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const rows = useMemo(
    () =>
      assetLists.map((assetList) => {
        const { id, name, fileName, numEmptyCells, numRows, created } =
          assetList;
        return {
          id,
          name,
          fileName,
          numEmptyCells,
          numRows,
          created,
        };
      }),
    [assetLists],
  );

  const actionButtons = ({ row }) => {
    const rowData = row.original;

    return [
      <TableActionButton
        key="Delete"
        text="Delete"
        colour="red"
        onClick={() => {
          deleteList(rowData);
        }}
      />,
      <TableActionButton
        key="Download"
        text="Download"
        colour="green"
        onClick={() => onClickDownloadTaxonomy(rowData)}
      />,
    ];
  };

  return (
    <Paper sx={{ width: '100%' }} id="main-content">
      <Table
        rows={rows}
        columns={columns}
        tableProps={{
          renderRowActions: actionButtons,
          enableRowActions: true,
          positionActionsColumn: 'first',
        }}
      />
    </Paper>
  );
};

export default Edit;
