import { ASSET_IMAGE_HEIGHT, DEFAULT_SPACING } from './settings';
import { Image, Link, Text, View } from '@react-pdf/renderer';

import { AssetDataRow } from '../types';
import { Footer } from './Footer';
import { Header } from './Header';
import { PageHolder } from './PageHolder';
import { TableOfContentsContext } from './TableOfContents';
import { getSafeTextFromText } from '../../../../../../shared/utilities';
import { styles } from './styles';
import { useContext } from 'react';

function removeParamsFromUrl(url: string) {
  return url.split('?')[0] ?? url;
}

const SHORT_URL_PREFIX = 'https://asseticom.co.uk/s/';

function addThumbParamToUrl(url: string) {
  //if its not a short url, because the url has not been shortened for whatever reason - return it as is
  if (url.startsWith(SHORT_URL_PREFIX) === false) {
    return url;
  }
  //ensure there arent already params present
  const withoutParams = removeParamsFromUrl(url);
  return `${withoutParams}?height=${ASSET_IMAGE_HEIGHT}`;
}

interface PhotographicSchedule {
  assets: AssetDataRow[];
  customFields: string[];
}

export function PhotographicSchedule({
  assets,
  customFields,
}: PhotographicSchedule) {
  const { addToTableOfContents } = useContext(TableOfContentsContext);
  const pageTitle = '2.0 - Photographic Schedule';

  const customFieldsSafe = customFields.map(
    (field) => getSafeTextFromText(field) as keyof AssetDataRow,
  );

  return (
    <>
      <PageHolder style={styles.page}>
        <Header />
        <Text
          style={styles.pageTitle}
          id={pageTitle}
          render={({ pageNumber, totalPages }) => {
            addToTableOfContents({
              title: pageTitle,
              pageNumber,
              totalPages,
              id: pageTitle,
            });
            return pageTitle;
          }}
        />
        <Footer />
      </PageHolder>
      <PageHolder
        style={{
          ...styles.page,
        }}
      >
        <Header />
        {assets.map((asset, index: number) => {
          const isNewRoom =
            index > 0 && asset.Room_ID !== assets[index - 1]?.Room_ID;
          const assetHierarchy = `${asset.Code_0} ${asset.Label_0} > ${asset.Code_1} ${asset.Label_1} > ${asset.Code_2} ${asset.Label_2}`;
          const link = `https://portal.asseticom.co.uk/${asset.Client_ID}/surveys/${asset.Survey_ID}/${asset.Room_ID}/${asset.ID}`;
          const floorAndRoomLinkText = `${
            asset.Floor_Name ?? 'Unknown Floor'
          } - ${asset.Room_Name}`;

          const uniqueRoomId = `${asset.Survey_ID}${asset.Room_ID}`;

          return (
            <View
              key={asset.ID}
              wrap={false}
              break={isNewRoom}
              style={{ marginTop: DEFAULT_SPACING }}
            >
              {index === 0 || isNewRoom ? (
                <Text
                  id={uniqueRoomId}
                  style={styles.newRoomText}
                  render={({ pageNumber, totalPages }) => {
                    if (asset.Survey_ID !== assets[index - 1]?.Survey_ID) {
                      addToTableOfContents({
                        title: asset.Survey_Name,
                        section: asset.Survey_ID,
                        pageNumber,
                        totalPages,
                        id: asset.Survey_Name,
                      });
                    }
                    addToTableOfContents({
                      title: floorAndRoomLinkText,
                      subSection: asset.Room_ID,
                      pageNumber,
                      totalPages,
                      id: uniqueRoomId,
                    });
                    return floorAndRoomLinkText;
                  }}
                ></Text>
              ) : (
                <></>
              )}
              <View
                style={{
                  backgroundColor: '#f0f0f066',
                  height: 20,
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: 5,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  justifyContent: 'center',
                  paddingHorizontal: DEFAULT_SPACING,
                  flexWrap: 'wrap',
                }}
              >
                <Text style={styles.assetHierarchyText}>{assetHierarchy}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  border: '1px solid lightgrey',
                  borderTop: 'none',
                  borderRadius: 5,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  padding: DEFAULT_SPACING,
                }}
              >
                <View
                  style={{
                    flex: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <View
                    style={{
                      ...styles.captionText,
                      paddingRight: DEFAULT_SPACING,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Text style={styles.boldText}>Asset Type - </Text>
                      <Text>{asset.Name}</Text>
                    </View>
                    {customFieldsSafe.map((field, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Text style={styles.boldText}>{field} - </Text>
                          <Text>{asset[field] ?? 'N/A'}</Text>
                        </View>
                      );
                    })}
                    <Link style={styles.button} href={link}>
                      View on Asseticom
                    </Link>
                  </View>
                </View>
                {asset.Image_1 ? (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      width: '100%',
                      height: ASSET_IMAGE_HEIGHT,
                      padding: DEFAULT_SPACING / 2,
                      border: '1px solid lightgrey',
                      borderRadius: 5,
                    }}
                  >
                    <Link
                      href={removeParamsFromUrl(asset.Image_1)}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <Image
                        src={addThumbParamToUrl(asset.Image_1)}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          objectPosition: 'center',
                        }}
                      />
                    </Link>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </View>
          );
        })}
        <Footer />
      </PageHolder>
    </>
  );
}
