import { Text, View } from '@react-pdf/renderer';

import { DEFAULT_SPACING } from './settings';
import { Footer } from './Footer';
import { Header } from './Header';
import { PageHolder } from './PageHolder';
import { SurveyMeta } from '../../../../../../types/survey';
import { TableOfContentsContext } from './TableOfContents';
import { styles } from './styles';
import { useContext } from 'react';

interface Introduction {
  reportTitle: string;
  clientName: string;
  siteName: string;
  surveys: SurveyMeta[];
  customFields: string[];
}

export function Introduction({
  reportTitle,
  clientName,
  siteName,
  surveys,
  customFields,
}: Introduction) {
  const { addToTableOfContents } = useContext(TableOfContentsContext);
  const pageTitle = '1.0 - Introduction';

  return (
    <PageHolder style={styles.page}>
      <Header />
      <Text
        style={styles.pageTitle}
        id={pageTitle}
        render={({ pageNumber, totalPages }) => {
          addToTableOfContents({
            title: pageTitle,
            pageNumber,
            totalPages,
            id: pageTitle,
          });
          return pageTitle;
        }}
      />
      <View>
        <Text style={styles.bodyText}>
          1.1 - This {reportTitle} has been prepared for {clientName} and
          details the photographic schedule for the assets contained therein.
        </Text>
        <Text style={styles.bodyText}>
          1.2 - The photographic schedule contained herein is based on the
          following surveys at {siteName}:
        </Text>
        <View
          style={{
            ...styles.tableContainer,
            marginBottom: DEFAULT_SPACING,
          }}
        >
          <View style={styles.tableHeaderRowContainer}>
            <Text style={styles.tableHeaderCell}>Name</Text>
            <Text style={styles.tableHeaderCell}>Building Name</Text>
            <Text style={styles.tableHeaderCell}>Asset Count</Text>
          </View>
          {surveys.map((survey) => (
            <View key={survey.id} style={styles.tableRowContainer}>
              <Text style={styles.tableCell}>{survey.name}</Text>
              <Text style={styles.tableCell}>{survey.buildingName}</Text>
              <Text style={styles.tableCell}>{survey.assets}</Text>
            </View>
          ))}
        </View>
        <Text style={styles.bodyText}>
          1.3 - The photographic schedule is a bespoke report and contains
          standard and custom information against each asset.
        </Text>

        <Text style={styles.bodyText}>
          1.4 - The custom fields against each asset contained within this
          report are:
        </Text>

        <View style={{ marginBottom: DEFAULT_SPACING }}>
          {customFields.length === 0 && (
            <Text
              style={{ ...styles.fieldsText, marginLeft: DEFAULT_SPACING * 2 }}
            >
              None selected
            </Text>
          )}
          {customFields.map((field, index) => (
            <Text
              key={index}
              style={{ ...styles.fieldsText, marginLeft: DEFAULT_SPACING * 2 }}
            >
              &bull; {field}
            </Text>
          ))}
        </View>

        <Text style={styles.bodyText}>
          1.5 - Please note that not all assets may contain data for every
          field. Where this is the case, an &apos;N/A&apos; will be noted.
        </Text>

        <Text style={styles.bodyText}>
          1.6 - Images within this photographic schedule are interactive.
          Clicking on them will take you to the Asseticom portal where you can
          view further information and image data on the asset if it is
          available.
        </Text>
        <Text style={styles.bodyText}>
          1.7 - Assets without an associated image will not appear in this
          report.
        </Text>
      </View>
      <Footer />
    </PageHolder>
  );
}
