import { Box, Theme, useTheme } from '@mui/material';
import { DocumentProps, PDFViewer } from '@react-pdf/renderer';
import {
  JSXElementConstructor,
  ReactElement,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { MAX_PREVIEW_WIDTH, PAGE_RATIO } from './settings';

import debounce from 'lodash.debounce';

interface ResizeablePDFViewer {
  children:
    | ReactElement<DocumentProps, string | JSXElementConstructor<any>>
    | undefined;
}

export function ResizeablePDFViewer({ children }: ResizeablePDFViewer) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const theme = useTheme<Theme>();

  function updateDimensions() {
    const rect = containerRef.current?.getBoundingClientRect();
    const width = Math.min(rect?.width ?? MAX_PREVIEW_WIDTH, MAX_PREVIEW_WIDTH);
    const height = width * PAGE_RATIO;
    setDimensions({
      width,
      height,
    });
  }

  const debounced = debounce(updateDimensions, 250);

  useLayoutEffect(() => {
    window.addEventListener('resize', debounced);
    updateDimensions();
    return () => {
      window.removeEventListener('resize', debounced);
    };
  }, []);
  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        backgroundColor: theme.palette.background.default,
      }}
      p={2}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <PDFViewer
        width={dimensions.width}
        height={dimensions.height}
        showToolbar={true}
      >
        {children}
      </PDFViewer>
    </Box>
  );
}
