import 'firebase/firestore';
import 'firebase/functions';

import { dateFromTimeStamp, timeFromTimeStamp } from '../shared/utilities';

import firebase from 'firebase/app';
import { last } from 'pdf-lib';

// Get Firestore instance
const db = firebase.firestore();

export const getLastTenUpdatedAssets = async () => {
  try {
    // Get all clients from Firestore
    const lastTenAssets = await db
      .collectionGroup('assets')
      .orderBy('lastUpdated.time', 'desc')
      .limit(10)
      .get();

    const output = lastTenAssets.docs.map((doc) => {
      const time = doc.data().lastUpdated.time;
      return {
        client: doc.data().clientId,
        survey: doc.data().surveyName,
        surveyor: doc.data().surveyorName,
        time: `${dateFromTimeStamp(time)} ${timeFromTimeStamp(time)}`,
        device:
          `${doc.data().lastUpdated.manufacturer} ${
            doc.data().lastUpdated.deviceName
          }` || 'Unknown',
        version:
          `Version ${doc.data().lastUpdated.release} Build ${
            doc.data().lastUpdated.dist
          }` || 'Unknown',
      };
    });
    console.log({ output });
    return output;
  } catch (error) {
    console.error(error);
  }
};
