import { BIG_ASSET_LIST_NUM_ROWS, PATHS } from '../config/constants';
import {
  fileNameAndExtensionFromPath,
  getBlobFile,
  getStorageForPath,
} from '../shared/utilities';
import { getLastUpdated, recordError } from '../shared/logTools';
import { gzipSync, strToU8 } from 'fflate';

import { AssetAttributes } from '../types/asset';
import { setSnackMessage } from './ui';

type AssetListSubmitProps = {
  name: string;
  fileName: string;
  assetList: AssetAttributes[];
};

function assetListAsJSON(assetList: AssetAttributes[]) {
  return JSON.stringify(assetList);
}

export const onNewAssetListSubmitted =
  ({ name, fileName, assetList }: AssetListSubmitProps) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      let xlsxUrl =
        'https://firebasestorage.googleapis.com/v0/b/asseticom-uk.appspot.com/o/testing%2FTrial-TAXONOMY-hierarchy-matrix-new-model.xlsx?alt=media&token=dc1fdf1a-c543-432e-87bb-ca3962752747';
      let jsonUrl = '';
      const firestore = getFirebase().firestore();
      const { files } = getState();
      const { client } = getState().firestore.data;
      const { clientId } = client;

      const assetListStorage = getStorageForPath(PATHS.STORAGE.TAXONOMY);

      const xlsxPath = `${clientId}/${fileName}.xlsx`;
      const xlsxRef = assetListStorage.ref(xlsxPath);

      const jsonName = `${clientId}/${name}.json.gzip`;

      const jsonPath = `${clientId}/${jsonName}`;
      const jsonRef = assetListStorage.ref(jsonPath);

      const { fileUrl } = files.assetList;
      try {
        const file = await getBlobFile(fileUrl);
        await xlsxRef.put(file);
        xlsxUrl = await xlsxRef.getDownloadURL();

        const jsonString = assetListAsJSON(assetList);

        const gzipped = gzipSync(strToU8(jsonString), {
          filename: `${name}.json`,
        });
        await jsonRef.put(gzipped, {
          cacheControl: 'public,max-age=4000',
        });
        jsonUrl = await jsonRef.getDownloadURL();
      } catch (error) {
        recordError(error);
        return window.alert(
          'There was an error uploading the asset taxonomy file, please try again.',
        );
      }

      const clientDocRef = await firestore.collection('clients').doc(clientId);
      const assetListDocRef = await clientDocRef.collection('assetLists').doc();
      await assetListDocRef.set({
        id: assetListDocRef.id,
        name,
        fileName,
        url: xlsxUrl,
        jsonUrl,
        created: Date.now(),
        deleted: 0,
        numRows: assetList.length,
        ...getLastUpdated(),
      });
      dispatch(
        setSnackMessage({
          message: 'Successfully uploaded the asset taxonomy',
        }),
      );
      return { id: assetListDocRef.id as string, error: undefined };
    } catch (error) {
      console.error(error);
      return { error, id: undefined };
    }
  };

export const onAssetListDelete =
  ({ id }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();
      const { files } = getState();
      const { client } = getState().firestore.data;
      const { clientId } = client;

      const clientDocRef = await firestore.collection('clients').doc(clientId);

      const assetListDocRef = clientDocRef.collection('assetLists').doc(id);

      clientDocRef.update({
        assetListsNumDeleted: firestore.FieldValue.increment(1),
      });
      await assetListDocRef.update({ deleted: Date.now() });

      dispatch(
        setSnackMessage({ message: 'Successfully deleted the asset taxonomy' }),
      );
      return true;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export default onNewAssetListSubmitted;
