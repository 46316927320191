import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { AppState } from '../../../../../../config/store';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function CompleteDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) {
  const client = useSelector((state: AppState) => state.firestore.data.client);
  const history = useHistory();
  const profile = useSelector((state: AppState) => state.firebase.profile);

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={() => {
        onClose?.();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Generating Report...</DialogTitle>
      <DialogContent>
        <Typography>Your report is now being generated.</Typography>
        <br />
        {profile.exportEmails && (
          <>
            <Typography>A link to download it will be sent to </Typography>
            <Typography sx={{ fontWeight: 'bold' }}>{profile.email}</Typography>
          </>
        )}
        <br />
        <Typography>
          You can view its progress, and download it directly from the next
          screen once complete.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            console.log('go to view');
            history.push(`${client.id}/reporting`);
          }}
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
