import { Document, Font } from '@react-pdf/renderer';
import {
  TableOfContents,
  TableOfContentsContext,
  TableOfContentsProvider,
} from './TableOfContents';
import { createContext, useContext, useRef } from 'react';

import { FrontPage } from './FrontPage';
import { InsidePage } from './InsidePage';
import RobotoFonts from './robotoFonts.json';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoFonts[300],
      fontWeight: 300,
    },
    {
      src: RobotoFonts[700],
      fontWeight: 700,
    },
  ],
});

interface BasePDFDocumentContext {
  headerData: {
    buildingName: string;
    reportTitle: string;
    clientLogo: string;
    themeColour: string;
  };
  footerData: {
    organisationName: string;
    organisationLogo: string;
    themeColour: string;
  };
}

interface BasePDFDocument {
  data: any;
  children: React.ReactNode;
  onRendered?: (data: any) => void;
  toc?: any;
}

export const BasePDFDocumentContext = createContext<BasePDFDocumentContext>(
  null as unknown as BasePDFDocumentContext,
);

export const BasePDFDocument = ({
  data,
  onRendered,
  toc,
  children,
}: BasePDFDocument) => {
  const firstRow = data.assetData[0];

  const headerData = {
    buildingName: firstRow?.Building_Name,
    reportTitle: data.reportTitle,
    clientLogo: data.clientLogo,
    themeColour: data.themeColour,
  };

  const footerData = {
    organisationName: data.organisationName ?? '',
    organisationLogo: data.organisationLogo,
    themeColour: data.themeColour,
  };

  return (
    <BasePDFDocumentContext.Provider value={{ headerData, footerData }}>
      <TableOfContentsProvider toc={toc}>
        <DocumentContent data={data} onRendered={onRendered}>
          {children}
        </DocumentContent>
      </TableOfContentsProvider>
    </BasePDFDocumentContext.Provider>
  );
};

function DocumentContent({ data, onRendered, children }: BasePDFDocument) {
  const prevTotal = useRef(0);
  const { tableOfContents, setTableOfContents, getEntries, getTotalPages } =
    useContext(TableOfContentsContext);

  return (
    <Document
      onRender={() => {
        //sort by page number as we can't guarantee the order of object properties
        const entries = Object.values(getEntries()).sort(
          (a, b) => a.pageNumber - b.pageNumber,
        );
        const totalPages = getTotalPages();
        console.log('finished render', {
          numEntries: entries.length,
          tocLen: tableOfContents.length,
          totalPages,
          prevTotal: prevTotal.current,
        });

        if (prevTotal.current !== getTotalPages()) {
          setTableOfContents(entries);
          prevTotal.current = totalPages;
          onRendered?.({ toc: entries });
        }
      }}
    >
      <FrontPage data={data} />
      <InsidePage data={data} />
      <TableOfContents />
      {children}
    </Document>
  );
}
