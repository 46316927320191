import { BasePDFDocument } from '../../../shared/pdf/BasePDFDocument';
import { CreatePhotoScheduleReportData } from '../../../shared/types';
import { Introduction } from '../../../shared/pdf/Introduction';
import { PhotographicSchedule } from '../../../shared/pdf/PhotographicSchedule';

export function PhotoSchedulePDFReport({
  data,
  onRendered,
  renderedTOC,
}: {
  data: CreatePhotoScheduleReportData;
  onRendered?: (toc: any) => void;
  renderedTOC?: any;
}) {
  const { clientName, reportTitle, field1, field2, field3, field4, field5 } =
    data;

  const firstAsset = data.assetData[0];
  const siteName = firstAsset?.Site_Name;

  const customFields = [field1, field2, field3, field4, field5].filter(
    (item): item is string => !!item,
  );

  const onlyAssetsWithImages = data.assetData.filter((asset) =>
    asset['Image_1']?.startsWith('https://'),
  );

  return (
    <BasePDFDocument data={data} onRendered={onRendered} toc={renderedTOC}>
      <Introduction
        reportTitle={reportTitle}
        siteName={siteName}
        clientName={clientName}
        customFields={customFields}
        surveys={data.surveys}
      />
      <PhotographicSchedule
        assets={onlyAssetsWithImages}
        customFields={customFields}
      />
    </BasePDFDocument>
  );
}
