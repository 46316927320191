import 'leaflet/dist/leaflet.css';

import { Box, Button, Paper, Typography } from '@mui/material';

import AddressForm from '../../../../components/AddressForm';
import { SiteProps } from '../../../../types/site';

type SiteFormProps = {
  site: SiteProps;
  onSiteSubmit: () => void;
};

const SiteForm = ({ site, onSiteSubmit }: SiteFormProps) => {
  return (
    <Paper elevation={2} sx={{ flex: 1 }} id="main-content">
      <Box p={2}>
        <Typography variant="subtitle2">Site Details</Typography>
        <AddressForm
          type="Site"
          formData={site}
          onSubmit={onSiteSubmit}
          buttons={
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mt={2}
            >
              <Button variant="contained" color="primary" type="submit">
                Save Site Details
              </Button>
            </Box>
          }
        />
      </Box>
    </Paper>
  );
};

export default SiteForm;
