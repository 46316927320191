import { DEFAULT_SPACING, FOOTER_HEIGHT, HEADER_HEIGHT } from './settings';

import { StyleSheet } from '@react-pdf/renderer';

const TEXT_COLOR_DARK_BLUE = '#363F72';
const TEXT_COLOR_DARK_GREY = '#344054';
// Create styles
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingTop: HEADER_HEIGHT + DEFAULT_SPACING,
    paddingBottom: FOOTER_HEIGHT + DEFAULT_SPACING,
    paddingHorizontal: DEFAULT_SPACING * 2,
    fontFamily: 'Roboto',
  },
  pageTitle: {
    fontWeight: 700,
    alignSelf: 'center',
    marginTop: DEFAULT_SPACING * 2,
    marginBottom: DEFAULT_SPACING * 2,
    fontSize: 22,
    color: TEXT_COLOR_DARK_BLUE,
  },
  subTitle: {
    fontWeight: 500,
    marginBottom: DEFAULT_SPACING,
    fontSize: 18,
    color: TEXT_COLOR_DARK_BLUE,
  },
  bodyText: {
    fontSize: 14,
    lineHeight: 1.2,
    marginBottom: DEFAULT_SPACING,
    fontWeight: 300,
    color: TEXT_COLOR_DARK_GREY,
  },
  tocText: {
    fontSize: 12,
    lineHeight: 1,
    marginBottom: DEFAULT_SPACING / 2,
    fontWeight: 300,
    color: TEXT_COLOR_DARK_GREY,
  },
  boldText: {
    fontWeight: 700,
  },
  captionText: {
    fontSize: 10,
    fontWeight: 300,
    color: TEXT_COLOR_DARK_BLUE,
  },
  fieldsText: {
    fontSize: 12,
    fontWeight: 300,
    color: TEXT_COLOR_DARK_BLUE,
  },
  assetHierarchyText: {
    fontSize: 7,
    fontWeight: 700,
  },
  newRoomText: {
    fontWeight: 700,
    alignSelf: 'center',
    marginBottom: DEFAULT_SPACING * 2,
    fontSize: 16,
    color: TEXT_COLOR_DARK_BLUE,
  },
  linkText: {
    textDecoration: 'none',
    color: TEXT_COLOR_DARK_GREY,
  },
  button: {
    width: 80,
    border: '1px solid lightgrey',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    padding: DEFAULT_SPACING / 4,
    textDecoration: 'none',
    color: '#333',
    cursor: 'pointer',
    fontSize: 8,
    marginTop: DEFAULT_SPACING,
  },
  header: {
    position: 'absolute',
    height: HEADER_HEIGHT,
    fontSize: 12,
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: TEXT_COLOR_DARK_BLUE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid grey',
  },
  footer: {
    position: 'absolute',
    height: FOOTER_HEIGHT,
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: TEXT_COLOR_DARK_BLUE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    borderTop: '1px solid grey',
  },
  tocItem: {
    fontSize: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  pageNumber: {
    fontSize: 12,
    textAlign: 'center',
    color: TEXT_COLOR_DARK_BLUE,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: 128,
    objectFit: 'contain',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: `1px solid ${TEXT_COLOR_DARK_GREY}`,
    borderBottom: `1px solid ${TEXT_COLOR_DARK_GREY}`,
  },
  tableHeaderRowContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableRowContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  tableHeaderCell: {
    width: '100%',
    textAlign: 'left',
    fontSize: 10,
    fontWeight: 700,
    color: TEXT_COLOR_DARK_BLUE,
    padding: `${DEFAULT_SPACING / 2} ${DEFAULT_SPACING}`,
  },
  tableCell: {
    width: '100%',
    textAlign: 'left',
    fontSize: 10,
    fontWeight: 300,
    color: TEXT_COLOR_DARK_GREY,
    padding: `${DEFAULT_SPACING / 2} ${DEFAULT_SPACING}`,
    borderTop: `1px solid ${TEXT_COLOR_DARK_GREY}`,
  },
});
