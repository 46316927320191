export const PAGE_HEIGHT = 841;
export const PAGE_WIDTH = 595;
export const PAGE_RATIO = PAGE_HEIGHT / PAGE_WIDTH;
export const MAX_PREVIEW_WIDTH = 640;
export const PREVIEW_HEIGHT = MAX_PREVIEW_WIDTH * PAGE_RATIO;
export const FIRST_FOOTER_PAGE = 3;
export const FIRST_HEADER_PAGE = 2;
export const ASSET_IMAGE_HEIGHT = 150;
export const HEADER_HEIGHT = 48;
export const FOOTER_HEIGHT = 48;
export const DEFAULT_SPACING = 12;
