import { SurveyMeta, SurveyProps } from '../types/survey';
import {
  autoSizeWorksheetWidth,
  getSafeExcelSheetName,
  getStyledExportHeaders,
} from '../shared/utilities';

import { Statuses } from '../types/status';
import XLSX from 'xlsx-js-style';
import firebase from 'firebase/app';

const rowStyle = {
  fill: { fgColor: { rgb: 'DDDDDD' } },
  font: { color: { rgb: '000000' } },
  border: {
    top: { style: 'thin', color: { rgb: 'FFFFFF' } },
    left: { style: 'thin', color: { rgb: 'FFFFFF' } },
    bottom: { style: 'thin', color: { rgb: 'FFFFFF' } },
    right: { style: 'thin', color: { rgb: 'FFFFFF' } },
  },
};

type ExportRow = {
  v: string;
  t: string;
  s: typeof rowStyle;
}[];

const ALL_COLUMNS = ['Client ID', 'Survey Name', 'Status Before'];

const headers = getStyledExportHeaders(ALL_COLUMNS);

const rows: ExportRow[] = [];

// this array can be used as either inclusive or exclusive
const CLIENTS = [
  // 'ColchesterGarrison',
  // 'MitieDemo',
  // 'SADC',
  // 'alstomrail',
  // 'amcor',
  'bandq',
  // 'bwcet',
  // 'cbre-experian',
  // 'cbreworkplacesolutions',
  // 'corkmaritime',
  // 'darylsportal',
  // 'demoportal-examplecompany',
];

async function processSurveys(clientId: string) {
  let numSurveys = 0;
  let numAffected = 0;
  if (CLIENTS.includes(clientId) === false) {
    console.log('skipping client', clientId);
    return Promise.resolve({ numSurveys, numAffected });
  }
  console.log('processing client', clientId);
  const firestore = firebase.firestore();

  const clientDocRef = firestore.collection('clients').doc(clientId);

  const surveyMetaDocRef = clientDocRef.collection('surveys-meta');

  const surveyMetaDocs = await surveyMetaDocRef.get();

  for (const surveyMetaDoc of surveyMetaDocs.docs) {
    const surveyMetaData = surveyMetaDoc.data() as SurveyMeta;

    const surveyIds = Object.keys(surveyMetaData);

    for (const surveyId of surveyIds) {
      numSurveys++;
      const survey = surveyMetaData[surveyId];

      if (survey?.status === Statuses.IN_PROGRESS) {
        const surveyDoc = await clientDocRef
          .collection('surveys')
          .doc(surveyId)
          .get();
        const surveyData = surveyDoc.data() as SurveyProps;

        if (surveyData?.status !== survey?.status) {
          numAffected++;
          console.warn(
            '*** survey meta status mismatch!',
            survey?.id,
            'has status',
            survey.status,
            'whereas the survey data has status',
            surveyData.status,
            '...fixing...****',
          );
          await surveyMetaDoc.ref.set(
            {
              [`${surveyId}`]: { status: surveyData.status },
            },
            { merge: true },
          );
          rows.push([
            { v: clientId, t: 's', s: rowStyle },
            { v: surveyData.name, t: 's', s: rowStyle },
            { v: survey.status, t: 's', s: rowStyle },
          ]);
        }
      }
    }
  }

  console.log({ numSurveys, numAffected });
  return Promise.resolve({ numSurveys, numAffected });
}

export default async function fixSurveyMetaStatuses() {
  let totalSurveys = 0;
  let totalAffected = 0;
  const workbook = XLSX.utils.book_new();
  const firestore = firebase.firestore();
  const clientDocs = await firestore.collection('clients').get();
  for (const clientDoc of clientDocs.docs) {
    const clientId = clientDoc.id;
    const { numSurveys, numAffected } = await processSurveys(clientId);
    totalSurveys += numSurveys;
    totalAffected += numAffected;
  }
  console.log(
    `finished processing ${totalSurveys} surveys, ${totalAffected} were affected`,
  );
  if (totalAffected) {
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], {
      origin: 'A1',
    });
    const workbookName = getSafeExcelSheetName(`Status Updates Export.xlsx`);
    const fixedSheet = await autoSizeWorksheetWidth(worksheet);
    XLSX.utils.book_append_sheet(workbook, fixedSheet, `Status Updates Export`);
    XLSX.writeFile(workbook, workbookName);
  }
}
