import 'virtual:svg-icons-register'; // eslint-disable-line
import './index.css';

import * as Sentry from '@sentry/react';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { isViewingProdServer } from './shared/utilities';

if (isViewingProdServer()) {
  Sentry.init({
    dsn: 'https://d69c28306d7a450c9741af20e4276b06@o1102480.ingest.sentry.io/6587485',
    normalizeDepth: 10,
    beforeSend(event, hint) {
      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (!breadcrumb.category) {
        return breadcrumb;
      }
      const DONT_WANT = ['xhr', 'console'];
      return DONT_WANT.includes(breadcrumb.category) ? null : breadcrumb;
    },
    environment:
      window.location.hostname.indexOf('staging') === 0
        ? 'staging'
        : import.meta.env.MODE,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
