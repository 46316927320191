import { Address } from '../../../../../types/site';
import { SurveyMeta } from '../../../../../types/survey';

export const ReportType = {
  ASSET_DATA: 'ASSET_DATA',
  PHOTO_SCHEDULE: 'PHOTO_SCHEDULE',
  LIFE_CYCLE: 'LIFE_CYCLE',
} as const;

export type ReportTypes = keyof typeof ReportType;

export type AssetLayoutTypes = 'THREE_PER_PAGE' | 'SIX_PER_PAGE';

export type Filters = {
  siteId: string;
  siteName: string;
  buildingId: string;
  buildingName: string;
  surveyId: string;
  surveyName: string;
  exportInProgressSurveysOnly: boolean;
  assetListIds: string[];
  surveyIds: string[];
};

export interface FormStep {
  handleBack?: () => void;
  handleNext: (
    data: Partial<PhotoScheduleReportData> | Partial<SpreadsheetReportData>,
  ) => void;
}

export type FrontPage = {
  organisationName?: string;
  organisationLogo?: string;
  clientLogo?: string;
  clientName?: string;
  coverImage?: string;
  reportDate: string;
  reportTitle: string;
  reportSubtitle?: string;
  themeColour: string;
};

export type InsidePage = {
  projectImage?: string;
  preparedByName: string;
  preparedByEmail?: string;
  preparedByPhone?: string;
  preparedBySignatureImage?: string;
  approvedByName: string;
  approvedByEmail?: string;
  approvedByPhone?: string;
  approvedBySignatureImage?: string;
} & Address;

export type PhotoScheduleReportSettings = {
  assetLayout: AssetLayoutTypes;
  field1?: string;
  field2?: string;
  field3?: string;
  field4?: string;
  field5?: string;
};

export type SpreadsheetReportSettings = {
  highlightChanges: boolean;
  includeDeletedAssets: boolean;
  useFriendlyImageLinks: boolean;
  consolidateToSingleSheet: boolean;
  lifeCycleYears?: number;
};

export type PhotoScheduleReportData = {
  clientId: string;
  clientName: string;
  reportType: ReportTypes;
} & Address &
  Filters &
  FrontPage &
  InsidePage &
  PhotoScheduleReportSettings;

export type SpreadsheetReportData = {
  clientId: string;
  clientName: string;
  reportType: ReportTypes;
} & Filters &
  SpreadsheetReportSettings;

export type AssetDataRow = { [key: string]: any };

export interface CreatePhotoScheduleReportData extends PhotoScheduleReportData {
  assetData: AssetDataRow[];
  surveys: SurveyMeta[];
}

export interface CreateSpreadsheetReportData extends SpreadsheetReportData {
  assetData?: AssetDataRow[];
}

export type TocEntry = { title: string; pageNumber: number };
