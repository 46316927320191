import { Image, Text, View } from '@react-pdf/renderer';

import { BasePDFDocumentContext } from './BasePDFDocument';
import { DEFAULT_SPACING } from './settings';
import { styles } from './styles';
import { useContext } from 'react';

export function Header() {
  const { headerData } = useContext(BasePDFDocumentContext);
  return (
    <View
      fixed
      style={{
        ...styles.header,
        alignItems: 'center',
        paddingHorizontal: DEFAULT_SPACING,
        paddingVertical: DEFAULT_SPACING / 2,
      }}
    >
      <View>
        <Text>{headerData.buildingName}</Text>
        <Text>{headerData.reportTitle}</Text>
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <View style={{ ...styles.image }}>
          {headerData.clientLogo && (
            <Image
              src={headerData.clientLogo}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'right',
              }}
            />
          )}
        </View>
      </View>
    </View>
  );
}
