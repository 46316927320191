import { Link, Text, View } from '@react-pdf/renderer';
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import { DEFAULT_SPACING } from './settings';
import { Footer } from './Footer';
import { Header } from './Header';
import { PageHolder } from './PageHolder';
import { styles } from './styles';

export type TocEntry = {
  title: string;
  pageNumber: number;
  totalPages: number;
  id: string;
  section?: string;
  subSection?: string;
};

type TocContextProps = {
  tableOfContents: TocEntry[];
  addToTableOfContents: (entry: TocEntry) => void;
  setTableOfContents: React.Dispatch<React.SetStateAction<TocEntry[]>>;
  getEntries: () => { [id: string]: TocEntry };
  getTotalPages: () => number;
};

export const TableOfContentsContext = createContext<TocContextProps>(
  null as unknown as TocContextProps,
);

let tocEntries: { [id: string]: TocEntry } = {};

const getEntries = () => tocEntries;

let totalPages = 0;

const getTotalPages = () => totalPages;

export const TableOfContentsProvider = ({
  toc = [],
  children,
}: {
  toc: TocEntry[] | undefined;
  children: React.ReactNode;
}) => {
  const [tableOfContents, setTableOfContents] = useState<TocEntry[]>(toc);

  const addToTableOfContents = (entry: TocEntry) => {
    if (!entry.totalPages) {
      //don't set the table of contents until we know how many pages there are
      return;
    }

    if (totalPages !== entry.totalPages) {
      // console.log('resetting', {
      //   totalNow: entry.totalPages,
      // });
      tocEntries = {};
    }

    if (!tocEntries) {
      // console.log('initialising', {
      //   entry,
      // });
      tocEntries = { [entry.id]: entry };
    } else {
      tocEntries[entry.id] = entry;
      // console.log('adding', {
      //   ...entry,
      // });
    }
    totalPages = entry.totalPages;
  };

  return (
    <TableOfContentsContext.Provider
      value={{
        tableOfContents,
        addToTableOfContents,
        setTableOfContents,
        getEntries,
        getTotalPages,
      }}
    >
      {children}
    </TableOfContentsContext.Provider>
  );
};

export function TableOfContents({ toc }: { toc?: TocEntry[] }) {
  const { tableOfContents } = useContext(TableOfContentsContext);

  return (
    <PageHolder style={styles.page}>
      <Header />
      <Text style={styles.pageTitle}>Table of Contents</Text>
      {(toc ?? tableOfContents).map(
        ({ title, pageNumber, section, subSection, id }) => {
          return section ? (
            <Text
              key={section + title}
              style={[
                styles.tocText,
                {
                  marginLeft: DEFAULT_SPACING,
                  marginTop: DEFAULT_SPACING / 2,
                  fontWeight: 700,
                },
              ]}
            >
              {title}
            </Text>
          ) : (
            <Link
              key={id}
              src={`#${id}`}
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: subSection ? DEFAULT_SPACING * 2 : 0,
              }}
            >
              <Text style={styles.tocText}>{title}</Text>
              <Text style={styles.tocText}>{pageNumber}</Text>
            </Link>
          );
        },
      )}

      <Footer />
    </PageHolder>
  );
}
