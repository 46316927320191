import { Image, Text, View } from '@react-pdf/renderer';

import { DEFAULT_SPACING } from './settings';
import { Footer } from './Footer';
import { InsidePage as IPType } from '../types';
import { PageHolder } from './PageHolder';
import { styles } from './styles';

interface InsidePage {
  data: IPType;
}

export function InsidePage({ data }: InsidePage) {
  return (
    <PageHolder
      style={{
        ...styles.page,
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      <View>
        <Text style={{ ...styles.bodyText, ...styles.boldText }}>Address</Text>
        <Text style={styles.bodyText}>{data.address1}</Text>
        {data.address2 && <Text style={styles.bodyText}>{data.address2}</Text>}
        <Text style={styles.bodyText}>{data.townCity}</Text>
        {data.county && <Text style={styles.bodyText}>{data.county}</Text>}
        {data.postCode && <Text style={styles.bodyText}>{data.postCode}</Text>}
      </View>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <View>
          <Text style={{ ...styles.bodyText, ...styles.boldText }}>
            Prepared By
          </Text>
          <Text style={styles.bodyText}>{data.preparedByName}</Text>
          {data.preparedByEmail && (
            <Text style={styles.bodyText}>{data.preparedByEmail}</Text>
          )}
          {data.preparedByPhone && (
            <Text style={styles.bodyText}>{data.preparedByPhone}</Text>
          )}

          {data.preparedBySignatureImage && (
            <Image
              src={data.preparedBySignatureImage}
              style={styles.image}
            ></Image>
          )}
        </View>

        <View>
          <Text
            style={{
              ...styles.bodyText,
              ...styles.boldText,
            }}
          >
            Approved By
          </Text>
          <Text style={styles.bodyText}>{data.approvedByName}</Text>
          {data.approvedByEmail && (
            <Text style={styles.bodyText}>{data.approvedByEmail}</Text>
          )}
          {data.approvedByPhone && (
            <Text style={styles.bodyText}>{data.approvedByPhone}</Text>
          )}

          {data.approvedBySignatureImage && (
            <Image
              src={data.approvedBySignatureImage}
              style={styles.image}
            ></Image>
          )}
        </View>
      </View>
      <Footer />
    </PageHolder>
  );
}
