import 'react-color-palette/css';

import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { AssetDataRow } from '../../../shared/types';
import { getTextFromSafeText } from '../../../../../../../shared/utilities';
import { useMemo } from 'react';

type FormData = {
  field1: string;
  field2: string;
  field3: string;
  field4: string;
  field5: string;
};

interface SettingsStep {
  defaultValues: Partial<FormData>;
  assetData: AssetDataRow[] | undefined;
  handleBack: (data: Partial<FormData>) => void;
  handleNext: (data: FormData) => void;
}

const DONT_DISPLAY = [
  'Timestamp',
  'Last Updated',
  'Created',
  'Deleted',
  'ID',
  'Parent ID',
  'Client ID',
  'Site Name',
  'Site ID',
  'Building Name',
  'Building ID',
  'Survey Name',
  'Survey ID',
  'Room Name',
  'Room ID',
  'Floor Name',
  'Surveyor ID',
  'Code 0',
  'Label 0',
  'Code 1',
  'Label 1',
  'Code 2',
  'Label 2',
  'Code 3',
  'Label 3',
  'Name',
  'Image 1',
  'Image 2',
  'Image 3',
  'Image 4',
  'Image 5',
  'full count',
];

function stripColumnsFromCustomFieldsList(columns: string[]) {
  return columns.filter((column) => {
    return DONT_DISPLAY.includes(column) === false;
  });
}

const CUSTOM_FIELDS = [1, 2, 3, 4, 5] as const;

export default function SettingsStep({
  defaultValues,
  assetData,
  handleBack,
  handleNext,
}: SettingsStep) {
  const customFieldOptions = useMemo(() => {
    const allColumns: string[] = Object.keys(assetData?.[0] || {}).map(
      getTextFromSafeText,
    );
    return stripColumnsFromCustomFieldsList(allColumns);
  }, [assetData]);

  console.log(customFieldOptions);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const values = useWatch({ control });

  console.log({ values });

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <Box py={2}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h5">Choose your settings</Typography>

          {/* <Divider flexItem /> */}
          {/* <Chip variant="outlined" label="Asset Image layout" color="primary" /> */}
          {/* <Stack>
            <FormAssetLayoutPicker />
          </Stack> */}

          <Divider flexItem />
          <Chip variant="outlined" label="Custom Fields" color="primary" />

          <Stack
            my={2}
            maxWidth="md"
            flexWrap={'wrap'}
            direction="row"
            justifyContent="center"
          >
            {CUSTOM_FIELDS.map((fieldNumber, index) => {
              const id = `field${fieldNumber}` as const;
              return (
                <FormControl
                  key={index}
                  variant="outlined"
                  error={!!errors[id]}
                  sx={{ width: 240, margin: 1 }}
                >
                  <InputLabel htmlFor={id}>
                    Custom Field {fieldNumber}
                  </InputLabel>
                  <Controller
                    name={id}
                    control={control}
                    render={({ field }) => (
                      <Select
                        variant="outlined"
                        native
                        label={`Custom Field ${fieldNumber}`}
                        {...field}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      >
                        <option key={0} value="" aria-label="empty" />
                        {customFieldOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              );
            })}
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent="space-between"
          mt={2}
        >
          <Button variant="outlined" onClick={() => handleBack(getValues())}>
            Back
          </Button>
          <Button variant="contained" type="submit">
            Next
          </Button>
        </Stack>
      </Box>
    </form>
  );
}
