import { Button, Stack } from '@mui/material';

import { CreatePhotoScheduleReportData } from '../../../shared/types';
import { PhotoSchedulePDFReport } from '../pdf/PhotoSchedulePDFReport';
import { ResizeablePDFViewer } from '../../../shared/pdf/ResizablePDFViewer';

interface PreviewStep {
  handleBack: () => void;
  handleNext: () => void;
  data: CreatePhotoScheduleReportData;
}

export default function PreviewStep({
  handleBack,
  handleNext,
  data,
}: PreviewStep) {
  console.log({ data });
  return (
    <form>
      <Stack
        py={2}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="column"
        sx={{ width: '100%' }}
      >
        <ResizeablePDFViewer>
          <PhotoSchedulePDFReport data={data} />
        </ResizeablePDFViewer>
      </Stack>
      <Stack direction={'row'} justifyContent="space-between">
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button variant={'contained'} onClick={handleNext}>
          Finish
        </Button>
      </Stack>
    </form>
  );
}
