import { DEFAULT_SPACING, FOOTER_HEIGHT } from './settings';
import { Image, Text, View } from '@react-pdf/renderer';

import { FrontPage as FPData } from '../types';
import { PageHolder } from './PageHolder';
import { asseticomLogoData } from './asseticom-logo';
import dayjs from 'dayjs';
import { styles } from './styles';

interface FrontPage {
  data: FPData;
}

export function FrontPage({ data }) {
  return (
    <PageHolder
      style={{
        ...styles.page,
        padding: DEFAULT_SPACING,
      }}
    >
      <Image
        src={asseticomLogoData}
        style={{
          ...styles.image,
          position: 'absolute',
          top: DEFAULT_SPACING,
          right: DEFAULT_SPACING,
        }}
      />
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {data.coverImage && (
          <View
            style={{
              marginBottom: DEFAULT_SPACING * 2,
              width: '100%',
              height: '40%',
            }}
          >
            <Image
              src={data.coverImage}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            ></Image>
          </View>
        )}
        <Text style={{ ...styles.pageTitle, marginBottom: DEFAULT_SPACING }}>
          {data.reportTitle}
        </Text>
        {data.reportSubtitle && (
          <Text style={styles.subTitle}>{data.reportSubtitle}</Text>
        )}
        <Text style={styles.bodyText}>
          {dayjs(data.reportDate).format('DD/MM/YYYY')}
        </Text>
        {/* <Text style={{ color: data.themeColour }}>THEME COLOUR</Text> */}
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            flexDirection: 'row',
            width: '100%',
            height: FOOTER_HEIGHT,
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          {data.clientLogo && (
            <Image
              src={data.clientLogo}
              style={{
                ...styles.image,
                objectPosition: 'left top',
              }}
            ></Image>
          )}
          {data.organisationLogo && (
            <Image
              src={data.organisationLogo}
              style={{ ...styles.image, objectPosition: 'right top' }}
            ></Image>
          )}
        </View>
      </View>
    </PageHolder>
  );
}
