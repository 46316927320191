import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import React, { useImperativeHandle, useRef, useState } from 'react';

import Cropper from 'react-easy-crop';
import FileDropper from './FileDropper';
import ImageZoomer from './ImageZoomer';
import { makeStyles } from '@mui/styles';

type FileUploaderCardProps = {
  title?: string;
  onChange: (file: File) => void;
  fileName?: string;
  fileUrl?: string;
  cropImage?: boolean;
  onCrop?: () => void;
  onRemove?: () => void;
  height?: string;
};

const useStyles = makeStyles((props: FileUploaderCardProps) => ({
  preview: {
    objectFit: 'contain',
    height: props.height || '200px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  fileDropper: {
    overflow: 'hidden',
    minHeight: 300,
  },
}));

const FileUploaderCard = (
  {
    title,
    onChange,
    fileName,
    fileUrl,
    cropImage,
    onCrop,
    onRemove,
    height = '200px',
  }: FileUploaderCardProps,
  ref,
) => {
  const classes = useStyles({ height });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const fileInputRef = useRef(ref);

  function clearValue() {
    fileInputRef.current.value = '';
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        clear: clearValue,
      };
    },
    [],
  );

  return (
    <Card>
      <CardContent>
        {title ? (
          <Box mb={2}>
            <Typography variant="subtitle2" gutterBottom>
              {title}
            </Typography>
          </Box>
        ) : null}

        <FileDropper
          onChange={onChange}
          prompt="Drop a file here to upload"
          height={height}
        >
          {fileName && (
            <Typography width="100%" variant="subtitle2" align="center">
              {fileName}
            </Typography>
          )}
          {fileUrl && cropImage ? (
            <Box className={classes.preview}>
              <Cropper
                image={fileUrl}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                showGrid={false}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCrop}
              />
            </Box>
          ) : (
            fileUrl && (
              <Box className={classes.preview}>
                <ImageZoomer
                  alt={title}
                  src={fileUrl}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
            )
          )}
        </FileDropper>
      </CardContent>
      <CardActions>
        {onRemove && fileName && (
          <Button
            component="label"
            onClick={() => {
              fileInputRef.current.value = '';
              onRemove?.();
            }}
          >
            Remove
          </Button>
        )}
        <Button component="label" color="primary">
          Upload
          <input
            onClick={clearValue}
            ref={fileInputRef}
            type="file"
            hidden
            name="uploadFile"
            onChange={(event) => {
              const file = event?.target?.files?.[0];
              file && onChange?.(file);
            }}
          />
        </Button>
      </CardActions>
    </Card>
  );
};

FileUploaderCard.displayName = 'fileUploaderCard';

export default React.forwardRef(FileUploaderCard);
