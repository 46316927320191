import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListSubheader,
  Switch,
  Typography,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { asseticomRouteConfig, clientRouteConfig } from '../config/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ALL_ROLES } from '../config/roles';
import AccessibleSwitcher from './AccessibleSwitcher';
import { AppState } from '../config/store';
import ComponentGuard from './ComponentGuard';
import { DRAWER_WIDTH } from '../config/constants';
import Drawer from '@mui/material/Drawer';
import DynamicIcon from './DynamicIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import UserInfo from './UserInfo';
import { getFirebase } from 'react-redux-firebase';
import { isViewingClientPortal } from '../shared/utilities';
import { setNavOpen } from '../state/ui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

const SideNav = () => {
  const firebase = getFirebase();
  const [routeConfig, setRouteConfig] = useState(asseticomRouteConfig);
  const [routePrefix, setRoutePrefix] = useState('');
  const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const navType = bigScreen ? 'persistent' : 'temporary';
  const uiState = useSelector((state: AppState) => state.ui);

  const location = useLocation();
  const client = useSelector((state: AppState) => state.firestore.data.client);

  useEffect(() => {
    if (client && isViewingClientPortal(client.clientId)) {
      setRouteConfig(clientRouteConfig);
      setRoutePrefix(`/${client.clientId}`);
    } else {
      setRoutePrefix('');
      setRouteConfig(asseticomRouteConfig);
    }
  }, [location, client]);

  const dispatch = useDispatch();

  const closeNav = () => {
    dispatch(setNavOpen({ open: false }));
  };

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
      }}
      elevation={20}
      open={bigScreen ? true : uiState.navOpen}
      variant={navType}
      onClose={closeNav}
    >
      <Toolbar color="secondary" />
      <Box
        sx={{
          width: DRAWER_WIDTH,
          overflow: 'auto',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        {!bigScreen && (
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ transform: `rotateY(180deg)` }}
              onClick={closeNav}
              size="large"
            >
              <DynamicIcon name="arrow" colour="secondary" size={20} />
            </IconButton>
          </Box>
        )}
        <UserInfo />
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          flex={1}
        >
          <List>
            {routeConfig.map((config) => {
              const { subHeading, auth: parentAuth } = config;

              return (
                <ComponentGuard auth={parentAuth} key={subHeading}>
                  <ListSubheader disableSticky sx={{ my: 1 }}>
                    {subHeading}
                  </ListSubheader>

                  {config.items.map((item) => {
                    const {
                      text,
                      path,
                      icon,
                      auth: childAuth,
                      accessibleText,
                    } = item;

                    const content = (
                      <>
                        <ListItemIcon>
                          {icon ? (
                            <DynamicIcon name={icon} colour="secondary" />
                          ) : null}
                        </ListItemIcon>

                        <AccessibleSwitcher
                          accessibleChild={
                            <ListItemText>{accessibleText}</ListItemText>
                          }
                        >
                          <ListItemText aria-hidden="true">{text}</ListItemText>
                        </AccessibleSwitcher>
                      </>
                    );

                    return (
                      <ComponentGuard auth={childAuth} key={text}>
                        <ListItem sx={{ padding: 0 }}>
                          {path.startsWith('http') ? (
                            <ListItemButton dense href={path} target="_blank">
                              {content}
                            </ListItemButton>
                          ) : (
                            <ListItemButton
                              dense
                              key={path}
                              component={NavLink}
                              to={`${routePrefix}${path}`}
                              activeClassName="Mui-selected"
                              onClick={closeNav}
                              exact
                            >
                              {content}
                            </ListItemButton>
                          )}
                        </ListItem>
                      </ComponentGuard>
                    );
                  })}
                </ComponentGuard>
              );
            })}
          </List>
          <Box>
            <List>
              <ListSubheader disableSticky>Links</ListSubheader>

              <ListItem sx={{ padding: 0 }}>
                <ListItemButton
                  dense
                  href="https://asseticom.co.uk/legal/accessibility"
                  target="_blank"
                >
                  <ListItemText>Accessibility Statement</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ padding: 0 }}>
                <ListItemButton
                  dense
                  href="https://asseticom.co.uk/legal/privacy"
                  target="_blank"
                >
                  <ListItemText>Privacy Policy</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={async () => {
                    await firebase.logout();
                  }}
                >
                  Log Out
                </Button>
              </ListItem>

              <ListItem dense>
                <Box my={2} flex={1}>
                  <Typography variant="caption">© Asseticom</Typography>
                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideNav;
