import { Box, Link, Paper } from '@mui/material';
import {
  getSafeTextFromText,
  getTextFromSafeText,
  isUrl,
} from '../../../../../../../shared/utilities';

import { AssetDataRow } from '../../../shared/types';
import { EXPORT_PREVIEW_COLUMN_ORDER } from '../../../../../../../config/constants';
import Table from '../../../../../../../components/Table';
import { useMemo } from 'react';

interface PreviewTable {
  rows: AssetDataRow[];
}

const IGNORE_COLUMNS = [
  'full_count',
  'Last_Updated',
  'ID',
  'Timestamp',
  'Created',
  'Deleted',
  'Relationship',
  'Parent_ID',
  'Client_ID',
  'Site_ID',
  'Building_ID',
  'Survey_ID',
  'Room_ID',
  'Surveyor_ID',
  'Site_Name',
  'Building_Name',
  'Survey_Name',
  'Room_Name',
  'Surveyor_Name',
  'Site_ID',
  'Code_0',
  'Code_1',
  'Code_2',
  'Code_3',
  'Label_0',
  'Label_1',
  'Label_2',
  'Label_3',
];

const PreviewTable = ({ rows }) => {
  const parsedRows = useMemo(() => {
    return rows.map((r: AssetDataRow) => {
      return {
        ...r,
        id: r.ID,
        Image_1: r.Image_1,
        Image_2: r.Image_2,
        Image_3: r.Image_3,
        Image_4: r.Image_4,
        Image_5: r.Image_5,
      };
    });
  }, [rows]);

  const columns = useMemo(() => {
    const parsedColumns = new Set<string>();
    if (parsedRows) {
      rows.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if (IGNORE_COLUMNS.includes(key) || parsedColumns.has(key)) {
            return;
          }
          parsedColumns.add(key);
        });
      });

      const sorted = Array.from(parsedColumns)
        .map((key) => {
          return {
            accessorKey: key,
            header: key,
            Cell: ({ cell }) => {
              if (key.startsWith('Image_') && isUrl(cell.getValue())) {
                return (
                  <Link
                    href={cell.getValue()}
                    target="_blank"
                    variant="caption"
                    color="secondary"
                    underline="hover"
                  >
                    Open
                  </Link>
                );
              }
              return <div>{cell.getValue() ?? 'N/A'}</div>;
            },
          };
        })
        .sort((a, b) => {
          if (EXPORT_PREVIEW_COLUMN_ORDER.indexOf(a.header) === -1) {
            return 1;
          }
          if (EXPORT_PREVIEW_COLUMN_ORDER.indexOf(b.header) === -1) {
            return -1;
          }
          return (
            EXPORT_PREVIEW_COLUMN_ORDER.indexOf(a.header) -
            EXPORT_PREVIEW_COLUMN_ORDER.indexOf(b.header)
          );
        });

      const fieldsWithoutImages = sorted.filter(
        (i: AssetDataRow) => i.header.indexOf('Image') === -1,
      );
      const onlyImageFields = sorted.filter(
        (i: AssetDataRow) => i.header.indexOf('Image') !== -1,
      );
      return [...fieldsWithoutImages, ...onlyImageFields];
    }
    return [];
  }, [parsedRows]);

  console.log({ parsedRows, rows, columns });

  return (
    <Paper sx={{ width: '100%' }}>
      <Table columns={columns} rows={parsedRows} disableSearchParams />
    </Paper>
  );
};

export default PreviewTable;
