import 'leaflet/dist/leaflet.css';

import AddressForm from '../../../components/AddressForm';
import BackButton from '../../../components/Form/BackButton';
import { Box } from '@mui/material';
import ButtonsContainer from '../../../components/Form/ButtonsContainer';
import NextButton from '../../../components/Form/NextButton';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({}));

const Location = ({ formData, onSubmit, onBack }) => {
  const classes = useStyles();

  return (
    <Box p={2}>
      <AddressForm
        onSubmit={onSubmit}
        showAddress
        formData={formData}
        name="Client"
        buttons={
          <ButtonsContainer>
            <BackButton onBack={onBack} />
            <NextButton />
          </ButtonsContainer>
        }
      />
    </Box>
  );
};

Location.propTypes = {
  formData: PropTypes.shape({
    postCode: PropTypes.string,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Location;
