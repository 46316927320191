import { SurveyMeta, SurveyProps } from '../types/survey';

import { ALL_ROLES } from '../config/roles';
import { AppState } from '../config/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type SurveysMetaInSections = {
  [section: string]: SurveyMeta;
};

export function parseSurveysMeta(
  surveysMetaInSections?: SurveysMetaInSections[],
) {
  const list: SurveyMeta[] = [];
  const byId: { [id: string]: SurveyMeta } = {};

  if (surveysMetaInSections) {
    for (const surveysMetaSection of Object.values(surveysMetaInSections)) {
      const surveys = Object.values(surveysMetaSection);

      for (const survey of surveys) {
        byId[survey.id] = survey;
        list.push(survey);
      }
    }
  }

  return { list, byId };
}

export default function useSurveysMeta() {
  const surveys = useSelector((state: AppState) => {
    return state.firestore.data.surveys;
  });

  const currentPortalUserRole = useSelector(
    (state: AppState) => state.firestore.data.currentPortalUserRole,
  );

  return useMemo(() => {
    const s = parseSurveysMeta(surveys);
    return {
      surveys:
        currentPortalUserRole?.role < ALL_ROLES.LEAD_SURVEYOR
          ? s?.list.filter((survey) => {
              return (
                survey.surveyorId &&
                survey.surveyorId === currentPortalUserRole?.uid
              );
            })
          : s.list,
      surveysById: s.byId,
    };
  }, [currentPortalUserRole, surveys]);
}
