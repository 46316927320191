import { AppState, useAppDispatch } from '../../../../../../config/store';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  onPortalLevelAssetDataExportSubmitted,
  onPortalLevelRoomDataExportSubmitted,
} from '../../../../../../state/asset-data';

import { ALL_ROLES } from '../../../../../../config/roles';
import ComponentGuard from '../../../../../../components/ComponentGuard';
import ErrorText from '../../../../../../components/Form/ErrorText';
import { ReportType } from '../types';
import { createPhotoScheduleReport } from './data-access';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

type FormData = {
  reportType: keyof typeof ReportType;
};

export default function ReportTypeStep({ handleNext, onChange, reportType }) {
  const { handleSubmit } = useForm<FormData>({ defaultValues: { reportType } });

  const dispatch = useAppDispatch();

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <Box py={2}>
        <Stack alignItems="center">
          <Stack spacing={2} maxWidth="md">
            {/* <Button
              onClick={() => {
                createPhotoScheduleReport({});
              }}
            >
              RUN TEST
            </Button> */}
            <Typography variant="h5" textAlign="center">
              Choose your report type
            </Typography>

            <Stack alignItems="center" spacing={2}>
              <Button
                sx={{ width: '50%' }}
                variant={
                  reportType === ReportType.ASSET_DATA
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => onChange(ReportType.ASSET_DATA)}
              >
                Asset Data
              </Button>

              <Typography variant="caption">
                All assets in the system in an Excel compatible format.
              </Typography>
              <Button
                sx={{ width: '50%' }}
                variant={
                  reportType === ReportType.LIFE_CYCLE
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => onChange(ReportType.LIFE_CYCLE)}
              >
                Life Cycle
              </Button>

              <Typography variant="caption">
                All assets in the system including life cycle costing, in an
                Excel compatible format.
              </Typography>
              <Button
                variant={
                  reportType === ReportType.PHOTO_SCHEDULE
                    ? 'contained'
                    : 'outlined'
                }
                sx={{ width: '50%' }}
                onClick={() => onChange(ReportType.PHOTO_SCHEDULE)}
              >
                Photo Schedule
              </Button>
              <Typography variant="caption">
                A photographic PDF report displaying asset conditions.
              </Typography>
              <ComponentGuard auth={ALL_ROLES.ASSETICOM_ADMIN}>
                <Button
                  color="info"
                  variant="contained"
                  sx={{ width: '50%' }}
                  onClick={() => {
                    dispatch(onPortalLevelAssetDataExportSubmitted());
                  }}
                >
                  Portal Level Asset Report
                </Button>
                <Typography variant="caption">
                  Contains asset data for the whole portal. Asseticom internal
                  use only.
                </Typography>

                <Button
                  color="info"
                  variant="contained"
                  sx={{ width: '50%' }}
                  onClick={() => {
                    dispatch(onPortalLevelRoomDataExportSubmitted());
                  }}
                >
                  Portal Level Room Report
                </Button>
                <Typography variant="caption">
                  Contains room schedule data for the whole portal. Asseticom
                  internal use only.
                </Typography>
              </ComponentGuard>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={2} justifyContent="flex-end">
          <Button variant={'contained'} type="submit">
            Next
          </Button>
        </Stack>
      </Box>
    </form>
  );
}
