import 'firebase/firestore';
import 'firebase/functions';

import { BigBatch } from '@qualdesk/firestore-big-batch';
import { ReportType } from '../routes/client-portal/reporting/create/shared/types';
import firebase from 'firebase/app';

// Get Firestore instance
const db = firebase.firestore();

export const addExportTypeToAllExports = async () => {
  try {
    // Get all exports from Firestore
    const allExports = await db.collectionGroup('exports').get();

    const batch = new BigBatch({ firestore: db });

    allExports.forEach((doc) => {
      batch.update(doc.ref, { reportType: ReportType.ASSET_DATA });
    });

    // Commit batched updates to Firestore
    // await batch.commit();

    console.log(`Updated ${allExports.size} exports`);
  } catch (error) {
    console.error(error);
  }
};
