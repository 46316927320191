import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useActorRef, useSelector } from '@xstate/react';

import { ALL_ROLES } from '../../../../../../config/roles';
import { AssetDataRow } from '../types';
import { BuildingProps } from '../../../../../../types/building';
import ComponentGuard from '../../../../../../components/ComponentGuard';
import ErrorText from '../../../../../../components/Form/ErrorText';
import { SiteProps } from '../../../../../../types/site';
import { SurveyMeta } from '../../../../../../types/survey';
import { createSpreadsheetReport } from './data-access';
import { filtersMachine } from './filters.machine';
import { useEffect } from 'react';
import useHookFormErrorHandler from '../../../../../../hooks/useFormErrorHandler';

type FormData = {
  siteId: string;
  buildingId: string;
  surveyId: string;
  exportInProgressSurveysOnly: boolean;
};

export type FiltersResponse = {
  siteId: string;
  buildingId: string;
  surveyId: string;
  sites: SiteProps[];
  buildings: BuildingProps[];
  surveys: SurveyMeta[];
  assetData: AssetDataRow[];
  exportInProgressSurveysOnly: boolean;
};

interface ReportFiltersProps {
  handleNext: (data: FiltersResponse) => void;
  handleBack: (data: Partial<FiltersResponse>) => void;
  clientId: string;
  defaultValues: Partial<FiltersResponse>;
  requiredFilters: {
    siteId?: boolean;
    buildingId?: boolean;
    surveyId?: boolean;
  };
}

export default function FiltersStep({
  handleNext,
  handleBack,
  clientId,
  defaultValues,
  requiredFilters = {
    siteId: true,
    buildingId: false,
    surveyId: false,
  },
}: ReportFiltersProps) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ defaultValues });

  useHookFormErrorHandler(errors, isSubmitting);

  const actorRef = useActorRef(filtersMachine, {
    input: { ...defaultValues, clientId },
  });

  const sites = useSelector(actorRef, (state) => state.context.sites);
  const buildings = useSelector(actorRef, (state) => state.context.buildings);
  const surveys = useSelector(actorRef, (state) => state.context.surveys);
  const assetData = useSelector(actorRef, (state) => state.context.assetData);
  const fetchingPreviewData = useSelector(actorRef, (state) =>
    state.matches('FetchingAssetData'),
  );
  const noPreviewDataFound = useSelector(actorRef, (state) =>
    state.matches('NoAssetDataFound'),
  );
  const state = useSelector(actorRef, (state) => state.context);

  console.log({ state });

  function mergeData(data: FormData) {
    const { siteId, buildingId, surveyId, exportInProgressSurveysOnly } = data;

    const {
      sites,
      buildings,
      surveys,
      assetData,
      siteName,
      buildingName,
      surveyName,
      surveyIds,
      assetListIds,
      address,
    } = actorRef.getSnapshot().context;

    console.log({ buildings, surveys });

    return {
      clientId,
      siteId,
      buildingId,
      surveyId,
      exportInProgressSurveysOnly,
      sites,
      buildings,
      surveys,
      assetData,
      siteName,
      buildingName,
      surveyName,
      surveyIds,
      assetListIds,
      ...address,
    };
  }

  function checkForAssetData() {
    actorRef.send({
      type: 'GET_ASSET_DATA',
    });
  }

  useEffect(() => {
    const subscription = actorRef.on('ASSET_DATA_RECEIVED', (event) => {
      handleNext?.(mergeData(getValues()));
    });
    return () => {
      return subscription.unsubscribe();
    };
  }, [actorRef]);

  return (
    <Box py={2}>
      <Stack spacing={2}>
        <Typography variant="h5" textAlign="center">
          Choose your filters
        </Typography>

        <form onSubmit={handleSubmit(checkForAssetData)}>
          <Stack alignItems="center">
            <Stack spacing={4} maxWidth="md" alignItems="center">
              <FormControl
                variant="outlined"
                sx={{ width: 240 }}
                error={!!errors.siteId}
              >
                <InputLabel htmlFor="site">Site</InputLabel>
                <Controller
                  name="siteId"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <Select
                      variant="outlined"
                      native
                      label="Site"
                      {...rest}
                      onChange={(event) => {
                        setValue('surveyId', '');
                        setValue('buildingId', '');
                        actorRef.send({
                          type: 'SITE_ID_SELECTED',
                          siteId: event.target.value,
                        });
                        onChange(event?.target.value);
                      }}
                    >
                      <option key={0} value="" aria-label="empty" />
                      {sites.map((site) => (
                        <option key={site.id} value={site.id}>
                          {site.name}
                        </option>
                      ))}
                    </Select>
                  )}
                  rules={{
                    required: requiredFilters.siteId,
                  }}
                />
                {!!errors.siteId && <ErrorText />}
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: 240 }}
                error={!!errors.buildingId}
                disabled={!buildings.length}
              >
                <InputLabel htmlFor="building">Building</InputLabel>
                <Controller
                  control={control}
                  name="buildingId"
                  render={({ field: { onChange, ...rest } }) => (
                    <Select
                      variant="outlined"
                      label="Building"
                      native
                      {...rest}
                      onChange={(event) => {
                        setValue('surveyId', '');
                        actorRef.send({
                          type: 'BUILDING_ID_SELECTED',
                          buildingId: event.target.value,
                        });
                        onChange(event.target.value);
                      }}
                    >
                      <option key={0} value="" aria-label="empty" />
                      {buildings.map((building) => (
                        <option key={building.id} value={building.id}>
                          {building.name}
                        </option>
                      ))}
                    </Select>
                  )}
                  rules={{
                    required: requiredFilters.buildingId,
                  }}
                />
                {!!errors.buildingId && <ErrorText />}
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: 240 }}
                error={!!errors.surveyId}
                disabled={!surveys?.length}
              >
                <InputLabel htmlFor="survey">Survey</InputLabel>
                <Controller
                  control={control}
                  name="surveyId"
                  render={({ field: { onChange, ...rest } }) => (
                    <Select
                      variant="outlined"
                      label="Survey"
                      native
                      {...rest}
                      onChange={(event) => {
                        actorRef.send({
                          type: 'SURVEY_ID_SELECTED',
                          surveyId: event.target.value,
                        });
                        onChange(event.target.value);
                      }}
                    >
                      <option key={0} value="" aria-label="empty" />
                      {surveys?.map((survey) => (
                        <option key={survey.id} value={survey.id}>
                          {survey.name}
                        </option>
                      ))}
                    </Select>
                  )}
                  rules={{
                    required: requiredFilters.surveyId,
                  }}
                />
              </FormControl>

              <ComponentGuard auth={ALL_ROLES.ASSETICOM_ADMIN}>
                <Controller
                  name="exportInProgressSurveysOnly"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ mr: 0 }}
                      label="Export in-progress surveys only"
                      control={
                        <Checkbox
                          {...field}
                          color="primary"
                          checked={!!field.value}
                          onChange={(e) => {
                            actorRef.send({
                              type: 'TOGGLE_IN_PROGRESS_SURVEYS_ONLY',
                              toggle: e.target.checked,
                            });
                            field.onChange(e.target.checked);
                          }}
                        />
                      }
                    />
                  )}
                />
              </ComponentGuard>
            </Stack>

            {fetchingPreviewData ? (
              <Box mt={4}>
                <Typography variant="caption" color="primary">
                  Fetching preview data...please wait
                </Typography>
                <LinearProgress />
              </Box>
            ) : noPreviewDataFound ? (
              <Box mt={4}>
                <ErrorText text="No data found for the selected filters" />
              </Box>
            ) : null}
          </Stack>
          <Stack
            direction={'row'}
            spacing={2}
            justifyContent="space-between"
            mt={2}
          >
            <Button
              variant="outlined"
              onClick={() => handleBack(mergeData(getValues()))}
            >
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}
