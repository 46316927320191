import {
  AssetDataRow,
  CreatePhotoScheduleReportData,
  CreateSpreadsheetReportData,
} from '../types';
import { firestore, functions } from '../../../../../../config/store';

import { BuildingProps } from '../../../../../../types/building';
import { SiteProps } from '../../../../../../types/site';
import { Statuses } from '../../../../../../types/status';
import { SurveyMeta } from '../../../../../../types/survey';
import dayjs from 'dayjs';
import { parseSurveysMeta } from '../../../../../../hooks/useSurveysMeta';
import { sortArrayByProp } from '../../../../../../shared/utilities';

interface GetAssetData {
  input: {
    clientId: string | undefined;
    siteId: string | undefined;
    buildingId: string | undefined;
    surveyId: string | undefined;
    exportInProgressSurveysOnly: boolean | undefined;
  };
}

export async function getAssetData({
  input: {
    clientId,
    siteId,
    buildingId,
    surveyId,
    exportInProgressSurveysOnly,
  },
}: GetAssetData): Promise<{
  rows: AssetDataRow[];
  surveyIds: string[];
  assetListIds: string[];
}> {
  try {
    const result = await functions.httpsCallable('getAssetDataPreview')({
      clientId,
      siteId,
      buildingId,
      surveyId,
      exportInProgressSurveysOnly,
      //use for testing, remove for production or set to 10
      rowsLimit: 10,
    });

    console.log({ result });

    return result?.data;
  } catch (err) {
    console.error(err);
    throw new Error('Unable to fetch asset data');
  }
}

export async function getSitesForClient({
  input: { clientId },
}: {
  input: { clientId: string | undefined };
}): Promise<SiteProps[]> {
  const siteDocs = await firestore
    .collection('clients')
    .doc(clientId)
    .collection('sites')
    .where('deleted', '==', 0)
    .orderBy('name', 'asc')
    .get();

  return siteDocs.docs.map((doc) => doc.data() as SiteProps);
}

export async function getBuildingsForSite({
  input: { clientId, siteId },
}: {
  input: {
    clientId: string | undefined;
    siteId: string | undefined;
  };
}): Promise<BuildingProps[]> {
  const buildingDocs = await firestore
    .collection('clients')
    .doc(clientId)
    .collection('sites')
    .doc(siteId)
    .collection('buildings')
    .where('deleted', '==', 0)
    .orderBy('name', 'asc')
    .get();

  return buildingDocs.docs.map((doc) => doc.data() as BuildingProps);
}

export async function getSurveysForBuilding({
  input: { clientId, buildingId, exportInProgressOnly },
}: {
  input: {
    clientId: string | undefined;
    buildingId: string | undefined;
    exportInProgressOnly: boolean | undefined;
  };
}): Promise<SurveyMeta[]> {
  const surveyDocs = await firestore
    .collection('clients')
    .doc(clientId)
    .collection('surveys-meta')
    .get();

  const parsed = parseSurveysMeta(
    surveyDocs.docs.map((doc) => doc.data()),
  ).list;

  const filtered = parsed.filter((survey) => {
    return (
      survey.status ===
        (exportInProgressOnly ? Statuses.IN_PROGRESS : Statuses.COMPLETED) &&
      survey.buildingId === buildingId
    );
  });
  console.log({ surveyDocs, exportInProgressOnly, filtered });

  return sortArrayByProp(filtered, 'name');
}

export async function createPhotoScheduleReport(
  data: CreatePhotoScheduleReportData,
) {
  const dataObj = {
    ...data,
    //have to convert the date to a string before sending to api
    reportDate: dayjs(data.reportDate).toISOString(),
  };
  console.log({ dataObj });
  const result = await functions.httpsCallable('createPhotoScheduleReport')(
    dataObj,
  );
  console.log({ result });
  return result;
}

export async function createSpreadsheetReport(
  data: Partial<CreateSpreadsheetReportData>,
) {
  const {
    assetListIds,
    clientId,
    clientName,
    siteId,
    siteName,
    buildingId,
    buildingName,
    surveyId,
    surveyIds,
    surveyName,
    lifeCycleYears,
    includeDeletedAssets,
    exportInProgressSurveysOnly,
    consolidateToSingleSheet,
    highlightChanges,
    useFriendlyImageLinks,
    reportType,
  } = data;

  const result = await functions.httpsCallable('createSpreadsheetReport')({
    assetListIds,
    clientId,
    clientName,
    siteId,
    siteName,
    buildingId,
    buildingName,
    surveyId,
    surveyIds,
    surveyName,
    lifeCycleYears,
    includeDeletedAssets,
    exportInProgressSurveysOnly,
    consolidateToSingleSheet,
    highlightChanges,
    useFriendlyImageLinks,
    reportType,
  });

  console.log({ result });
  return result;
}
