import { Image, Text, View } from '@react-pdf/renderer';

import { BasePDFDocumentContext } from './BasePDFDocument';
import { DEFAULT_SPACING } from './settings';
import { styles } from './styles';
import { useContext } from 'react';

export function Footer() {
  const { footerData } = useContext(BasePDFDocumentContext);
  return (
    <View
      fixed
      style={styles.footer}
      render={({ pageNumber }) => {
        return (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 1,
              paddingVertical: DEFAULT_SPACING / 2,
              padding: DEFAULT_SPACING,
            }}
          >
            <View style={{ flex: 1, textAlign: 'left' }}>
              {/* <Text>Prepared by {footerData.organisationName}</Text> */}
              <Text>Powered by Asseticom</Text>
            </View>
            <View>
              <Text>Page {pageNumber}</Text>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <View style={{ ...styles.image }}>
                {footerData.organisationLogo && (
                  <Image
                    src={footerData.organisationLogo}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'right top',
                    }}
                  />
                )}
              </View>
            </View>
          </View>
        );
      }}
    />
  );
}
